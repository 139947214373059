import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const PayNow = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const res = await fetch(`https://newserverforloanseoservice-5a7d7e182913.herokuapp.com/order/${id}`);
        const info = await res.json();
        setOrder(info);
      } catch (error) {
        console.error('Failed to fetch order details:', error);
      }
    };

    const fetchStripeKey = async () => {
      try {
        const res = await fetch('https://newserverforloanseoservice-5a7d7e182913.herokuapp.com/stripe-keys');
        const keys = await res.json();
        if (keys && keys.length > 0) {
          setStripePromise(loadStripe(keys[0].stripeKey));
        }
      } catch (error) {
        console.error('Failed to fetch Stripe key:', error);
      }
    };

    fetchOrder();
    fetchStripeKey();
  }, [id]);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#303238',
      colorDanger: '#df1b41',
      fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
    },
  };

  return (
    <>
      <HeaderBottom />
      <div>
        <section id="services" className="services-area pt-120 pb-90 fix mb-5 vh-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                {order && stripePromise && (
                  <Elements stripe={stripePromise} options={{ appearance }}>
                    <CheckoutForm order={order} />
                  </Elements>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PayNow;
