import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';

const CheckoutForm = () => {
  const [order, setOrder] = useState(null);
  const { id } = useParams();
  const [clientSecret, setClientSecret] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  // Fetch order details
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const res = await fetch(`https://newserverforloanseoservice-5a7d7e182913.herokuapp.com/order/${id}`);
        const data = await res.json();
        setOrder(data);
      } catch (error) {
        setErrorMessage('Failed to fetch order details');
        console.error(error);
      }
    };

    fetchOrder();
  }, [id]);

  // Create Payment Intent
  useEffect(() => {
    if (order && order.packagePrice) {
      const createPaymentIntent = async () => {
        try {
          const res = await fetch('https://newserverforloanseoservice-5a7d7e182913.herokuapp.com/create-payment-intent', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ amount: order.packagePrice }),
          });

          const data = await res.json();

          if (data.clientSecret) {
            setClientSecret(data.clientSecret);
          } else {
            throw new Error('Failed to get clientSecret from the response');
          }
        } catch (error) {
          setErrorMessage('Failed to create payment intent');
          console.error(error);
        }
      };

      createPaymentIntent();
    }
  }, [order]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: user?.email || 'unknown',
        name: order.customerName || 'anonymous',
        
      },
    });

    if (error) {
      setErrorMessage(error.message);
      console.error('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);

      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              email: user?.email || 'unknown',
              name: user?.displayName || 'anonymous'
            }
          }
        }
      );

      if (confirmError) {
        setErrorMessage(confirmError.message);
        console.error('[confirmError]', confirmError);
      } else {
        console.log('[PaymentIntent]', paymentIntent);
        setErrorMessage('');

        if (paymentIntent.status === "succeeded") {
          console.log('transaction id', paymentIntent.id);
          setTransactionId(paymentIntent.id);

          // Update payment status on the server
          try {
            const res = await fetch(`https://newserverforloanseoservice-5a7d7e182913.herokuapp.com/order/${id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ paymentStatus: 'Paid' }),
            });

            const data = await res.json();
            if (!res.ok) {
              throw new Error(data.error || 'Failed to update payment status');
            }
            navigate('/dashboard');
          } catch (error) {
            setErrorMessage('Failed to update payment status');
            console.error(error);
          }
        }
      }
    }
  };

  if (!order) {
    return <p>Loading...</p>;
  }

  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" className="btn btn-primary mt-5" disabled={!stripe || !clientSecret}>
        Pay Now {order.packagePrice}$
      </button>
      {errorMessage && <div className="alert alert-danger mt-3" role="alert">{errorMessage}</div>}
      {transactionId && <p className='text-primary mt-2'>
        Your transaction Id: {transactionId}
      </p>}
    </form>
  );
};

export default CheckoutForm;
